interface IHomeProps {
  introduction: string;
}

const Home: React.FunctionComponent<IHomeProps> = ({ introduction }) => {
  return (
    <section
      id="Home"
      style={{
        width: "100%",
        backgroundColor: "transparent",
        minHeight: "100vh",
      }}
      className="container"
    >
      <div className="container-introduction">
        <div className="container-avatar">
          <div
            style={{ position: "fixed" }}
            className="animate__animated animate__backInLeft animate__delay-1s"
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <img
                alt="Jesus Eusse Developer"
                src="https://jesuseusse.s3.amazonaws.com/unnamed.jpg"
                style={{ width: "50vh", height: "50vh" }}
                className="custom-avatar animate__animated animate__pulse animate__slow	 animate__infinite"
              />
            </div>
          </div>
        </div>
        <div className="container-text">
          <div className="text-box animate__animated animate__backInRight animate__delay-1s">
            <h2 className="text-shadow-black">Hi, I'm Jesus Eusse</h2>
            <h3 className="text-shadow-black">{introduction}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
