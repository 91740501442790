import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { theme } from "../config/theme";

const Demos = () => {
  return (
    <section
      style={{
        backgroundColor: theme.palette.neutral.main,
        minHeight: "100vh",
        width: "100%",
        position: "relative",
      }}
    >
      <div className="box-id" id="Demos" />
      <Container>
        <Grid container flexDirection="column" justifyContent="space-around">
          <Grid item>
            <Grid
              container
              flexDirection="row"
              justifyContent="center"
              py={8}
              spacing={4}
            >
              <Grid item>
                <Card sx={{ width: "300px", height: "450px" }}>
                  <CardMedia
                    component="img"
                    height="300px"
                    image="/img/the_movies_app.png"
                    alt="The movies app"
                    sx={{ objectFit: "center" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      The movies App
                    </Typography>
                    <Typography variant="body2">
                      This is just a little demo developed with{" "}
                      <Link href="https://flutter.dev/" target="_blank">
                        flutter
                      </Link>{" "}
                      and available in Play Store for free.
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button size="large">
                      <Link
                        href="https://play.google.com/store/apps/details?id=com.jesusdev.movies_app"
                        target="_blank"
                      >
                        Open
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ width: "300px", height: "450px" }}>
                  <CardMedia
                    component="img"
                    height="300px"
                    image="/img/my_store.png"
                    alt="My store"
                    sx={{ objectFit: "center" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Tienda Eusse
                    </Typography>
                    <Typography variant="body2">
                      This is a project in progress
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button size="large">
                      <Link
                        href="https://my-own-store.web.app/"
                        target="_blank"
                      >
                        Open
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ width: "300px", height: "450px" }}>
                  <CardMedia
                    component="img"
                    height="300px"
                    alt="Whatsapp Text Creator"
                    sx={{ objectFit: "center" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Create Whatsapp messages easily
                    </Typography>
                    <Typography variant="body2">
                      Open a Whatsapp chat without save the phone number, this
                      is a project in progress
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button size="large">
                      <Link
                        href="https://whatsapp-text-creator.web.app/"
                        target="_blank"
                      >
                        Open
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ width: "300px", height: "450px" }}>
                  <CardMedia
                    component="img"
                    height="300px"
                    alt="Aromitas Menu"
                    image="/img/aromitas.jpeg"
                    sx={{ objectFit: "center" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      A little store without DB
                    </Typography>
                    <Typography variant="body2">
                      This is a project in progress
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button size="large">
                      <Link
                        href="https://aromitas-a336b.firebaseapp.com/"
                        target="_blank"
                      >
                        Open
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Demos;
