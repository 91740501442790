export const NavBar = () => {
  return (
    <nav>
      <input id="menu-toggle" type="checkbox" />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul
        className="menu"
        onClick={() => {
          const button = document.getElementById(
            'menu-toggle'
          ) as HTMLInputElement
          if (button) {
            button.checked = !button.checked
          }
        }}
      >
        <li>
          <a href="#Home">HOME</a>
        </li>
        <li>
          <a href="#About">ABOUT</a>
        </li>
        <li>
          <a href="#Resume">RESUME</a>
        </li>
        <li>
          <a href="#Contact">CONTACT</a>
        </li>
        <li>
          <a href="#Demos">DEMOS</a>
        </li>
      </ul>
      <a className="link" href="#Home">
        HOME
      </a>
      <a className="link" href="#About">
        ABOUT
      </a>
      <a className="link" href="#Resume">
        RESUME
      </a>
      <a className="link" href="#Contact">
        CONTACT
      </a>
      <a className="link" href="#Demos">
        DEMOS
      </a>
    </nav>
  )
}
