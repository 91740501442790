import { IconButton } from '@mui/material'
import SocialIcon from './widgets/SocialIcons'

interface IContactProps {
  socialMedia: Array<Record<string, string>>
}

const Contact: React.FunctionComponent<IContactProps> = ({ socialMedia }) => {
  return (
    <section
      style={{
        width: '100%',
        backgroundColor: 'transparent',
        padding: '24px 0',
        height: '100vh'
      }}
    >
      <div
        id="Contact"
        style={{
          width: '100%',
          height: '100vh'
        }}
      >
        <div className="container-social-links">
          <div className="box-links">
            <h2>Take a look at my social media!</h2>

            <ul
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexFlow: 'wrap',
                padding: 0
              }}
            >
              {socialMedia.length &&
                socialMedia.map(
                  (item) =>
                    SocialIcon[item.type] && (
                      <li key={item.link}>
                        <IconButton
                          color="primary"
                          sx={{
                            '&hover': { boxShadow: '5px 10px #888888' }
                          }}
                        >
                          <a href={item.link} target="_blank" rel="noreferrer">
                            {SocialIcon[item.type]}
                          </a>
                        </IconButton>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
