interface IAboutProps {
  about: string
}

const About: React.FunctionComponent<IAboutProps> = ({ about }) => {
  return (
    <div className="about-box">
      <div id="About" className="box-id" />
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 lang="en-US">About me</h2>
            <h3 lang="en-US">{about}</h3>
          </div>
          <div className="container-square">
            <div className="square">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <h3 lang="en-US">I also know how to center a div</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
