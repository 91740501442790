import { Email, Instagram, LinkedIn, Twitter } from '@mui/icons-material'

export const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer__col">
          <h3>Contact</h3>
          <ul>
            <li>
              <a
                href="mailto:jneusse_y@hotmail.com"
                rel="noreferrer"
                target="_blank"
              >
                <Email
                  sx={{
                    fontSize: 24,
                    color: 'white',
                    marginRight: '16px'
                  }}
                />
                Email
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__col">
          <h3>Social Network</h3>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/jesuseusse/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedIn
                  sx={{
                    fontSize: 24,
                    color: 'white',
                    marginRight: '16px'
                  }}
                />
                Linkedin
              </a>
            </li>
            <li>
              <a
                href="http://www.instagram.com/yisuseu"
                rel="noreferrer"
                target="_blank"
              >
                <Instagram
                  sx={{
                    fontSize: 24,
                    color: 'white',
                    marginRight: '16px'
                  }}
                />
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/yisus_developer"
                rel="noreferrer"
                target="_blank"
              >
                <Twitter
                  sx={{
                    fontSize: 24,
                    color: 'white',
                    marginRight: '16px'
                  }}
                />
                Twitter
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__privacy">
        <h4>
          <a href="/privacy-policy">Privacy policy</a>
        </h4>
      </div>
    </footer>
  )
}
