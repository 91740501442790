type LanguagesValuesType = {
  basic?: string[]
  intermediate?: string[]
  avanced?: string[]
  native: string[]
}

type SkillValueType = {
  name: string
}

type SkillsType = {
  programmingLanguages: SkillValueType[]
  frameworks: SkillValueType[]
  database: SkillValueType[]
  tools: string[]
  languages: LanguagesValuesType
}

type EducationType = {
  name: string
  year: string
  location: string
  title: string
}

type ProjectType = {
  position: string
  projectName: string
  fromDate?: string
  toDate?: string
  description: string[]
  techStack?: string
  links?: string[]
}

type ExperienceType = {
  companyName: string
  fromDate?: string
  toDate?: string
  position?: string
  description?: string[]
  techStack?: string
  projects?: ProjectType[]
}
interface IResumeProps {
  experience: ExperienceType[]
  education: EducationType[]
  skills: SkillsType
}

const Resume: React.FunctionComponent<IResumeProps> = ({
  experience,
  education,
  skills
}) => {
  const { programmingLanguages, frameworks, database } = skills
  return (
    <div className="container-resume">
      <div id="Resume" className="box-id" />
      <div className="container">
        <div className="box-resume">
          <div
            className="box-resume-title" /* xs={12} md={6} textAlign={{ xs: 'left', md: 'right' }}*/
          >
            <h2>Education</h2>
          </div>
          <div className="box-resume-info">
            {education &&
              education.map((item, index) => (
                <div key={index}>
                  <h4>{item.name}</h4>
                  <p>
                    {item.location}, {item.title}, {item.year}
                  </p>
                </div>
              ))}
          </div>
          <div
            className="box-resume-title" /* xs={12} md={6} textAlign={{ xs: 'left', md: 'right' }}*/
          >
            <h2>Work</h2>
          </div>
          <div className="box-resume-info">
            {experience.map((item, index) => (
              <div key={index}>
                <h4>{item.companyName}</h4>
                {item.position && item.fromDate && (
                  <h5>
                    {item.position} | {item.fromDate} - {item.toDate}
                  </h5>
                )}
                {item.description && (
                  <ul>
                    {item.description.map((item, index) => (
                      <li key={index} className="item_description">
                        <p style={{ textAlign: 'left' }}>{item}</p>
                      </li>
                    ))}
                  </ul>
                )}
                {item.techStack && (
                  <p style={{ textAlign: 'left' }}>
                    Tech Stack: {item.techStack}
                  </p>
                )}
                {item.projects && <h5>Projects</h5>}
                {item.projects && (
                  <ul>
                    {item.projects.map((itemProject, index) => (
                      <li key={index} className="item_description">
                        <p style={{ textAlign: 'left' }}>
                          {itemProject.position}, {itemProject.projectName},{' '}
                          {itemProject.fromDate} - {itemProject.toDate}
                        </p>
                        <ul>
                          {itemProject.description.map((itemDesc, i) => (
                            <li className="item_description" key={i}>
                              <p style={{ textAlign: 'left' }}>{itemDesc}</p>
                            </li>
                          ))}
                        </ul>
                        {itemProject.links && (
                          <ul style={{ textAlign: 'left' }}>
                            {itemProject.links.map((itemLink, i) => (
                              <li className="item_description" key={i}>
                                <a
                                  href={itemLink}
                                  target="_blank"
                                  className="project_link"
                                  rel="noreferrer"
                                >
                                  {itemLink}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                        <br></br>
                        {itemProject.techStack && (
                          <p style={{ textAlign: 'left' }}>
                            Tech Stack: {itemProject.techStack}
                          </p>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
          <div className="box-resume-title">
            <h2>Skills</h2>
          </div>
          <div className="box-resume-info">
            <h4>Programming Languages</h4>
            <div className="skills-item">
              {programmingLanguages.map((item, index) => (
                <span className="skill" key={index}>
                  {item.name}
                </span>
              ))}
            </div>
            <h4>Frameworks</h4>
            <div className="skills-item">
              {frameworks.map((item, index) => (
                <span className="skill" key={index}>
                  {item.name}
                </span>
              ))}
            </div>
            <h4>Data Base</h4>
            <div className="skills-item">
              {database.map((item, index) => (
                <span className="skill" key={index}>
                  {item.name}
                </span>
              ))}
            </div>
            <h4>Tools</h4>
            <div className="skills-item">
              {skills.tools.map((item, index) => (
                <span className="skill" key={index}>
                  {item}
                </span>
              ))}
            </div>
            <h4>Languages</h4>
            <h5>
              Native:{' '}
              {skills.languages.native.map(
                (item, index) =>
                  `${item}${
                    index !== skills.languages.native?.length! - 1 ? ',' : ''
                  } `
              )}
            </h5>
            {skills.languages.avanced?.length! > 0 && (
              <h5>
                Avanced:{' '}
                {skills.languages.avanced!.map(
                  (item, index) =>
                    `${item}${
                      index !== skills.languages.avanced?.length! - 1 ? ',' : ''
                    } `
                )}
              </h5>
            )}
            {skills.languages.intermediate?.length! > 0 && (
              <h5>
                Intermediate:{' '}
                {skills.languages.intermediate!.map(
                  (item, index) =>
                    `${item}${
                      index !== skills.languages.intermediate?.length! - 1
                        ? ','
                        : ''
                    } `
                )}
              </h5>
            )}
            {skills.languages.basic?.length! > 0 && (
              <h5>
                Basic:{' '}
                {skills.languages.basic!.map(
                  (item, index) =>
                    `${item}${
                      index !== skills.languages.basic?.length! - 1 ? ',' : ''
                    } `
                )}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume
