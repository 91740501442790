import { Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './config/theme'
import Home from './componets/Home'
import About from './componets/About'
import Resume from './componets/Resume'
import Contact from './componets/Contact'
import data from './data.json'
import PrivacyPolicy from './componets/PrivacyPolicy'
import Demos from './componets/Demos'
import { Footer } from './componets/Footer'
import { NavBar } from './componets/NavBar'
import './scss/app.scss'
import 'animate.css'

const HomeRoute = () => {
  return (
    <>
      <NavBar />
      <div className="content-body">
        <Home introduction={data.introduction} />
        <About about={data.about} />
        <Resume
          experience={data.experience}
          education={data.education}
          skills={data.skills}
        />
        <Contact socialMedia={data.socialMedia} />
        <Demos />
        <Footer />
      </div>
    </>
  )
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<HomeRoute />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/componets" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App
